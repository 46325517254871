<template>
    <div>
      <div style="padding: 5px 0">
        <el-input v-model="search.meetingName" @keyup.enter.native="load" style="width: 200px;margin-right: 10px;" placeholder="请输入会议名称">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-input v-model="search.headerName" @keyup.enter.native="load" style="width: 200px" placeholder="请输入负责人姓名">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button @click="load" type="primary" size="mini" style="margin: 10px" icon="el-icon-search">搜索</el-button>
        <el-button size="medium" class="el-icon-refresh" style="float: right;border: none;" @click="load">刷新数据</el-button>
      </div>
      <el-table :data="tableData" border stripe v-loading="loading" style="width: 100%">
        <el-table-column label="序号" width="100" type="index" :index="indexMethod"></el-table-column>
        <el-table-column prop="meetingName" label="会议名称"></el-table-column>
        <el-table-column prop="userId" label="负责人工号" width="80">
      </el-table-column>
        <el-table-column prop="headerName" label="负责人">
        </el-table-column>
        <el-table-column prop="meetingPlace" label="会议地点"></el-table-column>
        <el-table-column prop="meetingLevel" label="会议等级">
          <template slot-scope="scope">
            <p v-if="scope.row.meetingLevel === 1">校级</p>
            <p v-if="scope.row.meetingLevel === 2">系级</p>
            <p v-if="scope.row.meetingLevel === 3">班级</p>
          </template>
        </el-table-column>
        <el-table-column prop="meetingStatus" label="会议状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.meetingStatus === 2">未审核</el-tag>
          </template>
        </el-table-column>

        <el-table-column
        prop="totalNumber"
        label="总人数"
        width="60"
      ></el-table-column>

        <el-table-column prop="isSignin" label="是否签到" width="50">
        <template slot-scope="scope">
          <p v-if="scope.row.isSignin === '0'">否</p>
          <p v-if="scope.row.isSignin === '1'">是</p>
        </template>
      </el-table-column>
      <el-table-column prop="isSignout" label="是否签退" width="50">
        <template slot-scope="scope">
          <p v-if="scope.row.isSignout === '0'">否</p>
          <p v-if="scope.row.isSignout === '1'">是</p>
        </template>
      </el-table-column>

        <el-table-column prop="startTime" label="开始日期"></el-table-column>
        <el-table-column prop="endTime" label="结束日期"></el-table-column>
        <!-- <el-table-column prop="meetingContent" label="会议描述"></el-table-column> -->
        <!-- <el-table-column label="图片"><template slot-scope="scope"><el-image style="width: 100px; height: 100px" :src="scope.row.img" :preview-src-list="[scope.row.img]"></el-image></template></el-table-column> -->
  
        <el-table-column
            fixed="right"
            label="操作"
            width="200">
          <template slot-scope="scope">
            <!-- 
              <el-button v-if="scope.row.meetingStatus === 1" type="primary" icon="el-icon-edit" circle  @click="edit(scope.row)"></el-button>
            <el-button v-else-if="scope.row.meetingStatus === 2" type="primary" icon="el-icon-edit" circle  @click="edit(scope.row)"></el-button>
  
            <el-button v-else disabled type="primary" icon="el-icon-edit" circle  @click="edit(scope.row)"></el-button>
            <el-popconfirm
                @confirm="del(scope.row.id)"
                title="确定删除？"
            > 
              <el-button type="danger" icon="el-icon-delete" circle slot="reference" style="margin-left: 10px"></el-button>
            </el-popconfirm>
             -->
            
            <el-button @click="isCheck(scope.row)"  type="primary" round style="margin-left:10px" size="mini">通过</el-button>
            <el-button @click="isNotCheck(scope.row)" type="danger" round style="margin-left:10px" size="mini">不通过</el-button>
            
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[2, 5, 10]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
  
    </div>
  </template>
  
  <script>
  import API from '../../utils/request'
  const url = "/api/check/"
  
  export default {
    name: "IsCheck",
    data() {
      return {
        loading:false,
        fileList: [],
        options: [],
        text: '',
        user: {},
        tableData: [],
        pageNum: 1,
        pageSize: 10,
        entity: {},
        total: 0,
        dialogFormVisible: false,
        userInfo:[],
        search:{
          meetingName:'',
          header:''
        }
      };
    },
    created() {
      this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
      this.load()
    },
    methods: {
      indexMethod(index) {
        return index + 1
      },
      fileSuccessUpload(res) {
        this.entity.file = "http://localhost:9999/files/" + res.data;
        this.entity.img = "http://localhost:9999/files/" + res.data;
        this.fileList = [res.data]
        console.log('file.res',res)
      },
      handleSizeChange(pageSize) {
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum) {
        this.pageNum = pageNum
        this.load()
      },
      load() {
         API.post(url + "notCheckPage/" + this.pageNum + '/'+ this.pageSize,this.search).then(res => {
            this.tableData = res.data.records || []
            this.total = res.data.total
         })
  
         API.get("/api/level").then(res => {
           this.options = res.data
         })
         API.get("/api/user").then(res => {
           this.userInfo = res.data
         })
      },
      isCheck(isCheckInfo) {
        this.entity = JSON.parse(JSON.stringify(isCheckInfo))
        API.put(url + "updateCheck",this.entity).then(res =>{
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "操作成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load()
        })
        
      },
      isNotCheck(isCheckInfo) {
        this.entity = JSON.parse(JSON.stringify(isCheckInfo))
        API.put(url + "updateNotCheck",this.entity).then(res =>{
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "操作成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load()
        })
      }
    },
  };
  </script>
  
  <style scoped>
  </style>
  